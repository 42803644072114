<template>
  <div class="one-column-width">
    <!-- show action result -->
    <v-snackbar v-model="snackbar" absolute top right color="success">
      <span>{{ snackBarText }}</span>
      <v-icon dark>mdi-checkbox-marked-circle</v-icon>
    </v-snackbar>

    <v-snackbar v-model="errorMessage" absolute top right color="danger">
      <span>Something went RONG, Please Try Again!</span>
      <v-icon dark>mdi-checkbox-marked-circle</v-icon>
    </v-snackbar>

    <!-- Confirm Delete Item -->

    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="380px">
        <v-card>
          <v-card-title>
            <span class="headline">Do you really want to DELETE this item?</span>
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="custom-submit-button" text @click="dialog = false">No</v-btn>
            <v-btn class="custom-cancel-button" text @click="deleteItem"
              ><b-spinner small type="grow" v-if="deleting"></b-spinner>Delete</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- form start -->
    <v-card class="add-form-title" v-if="showFormData">
      <v-card-title> Add New Departments </v-card-title>
      <div>
        <v-form ref="form" @submit.prevent="submit" class="custom-form">
          <v-container fluid>
            <v-col cols="12" sm="9">
              <v-row v-for="(item, index) in form" :key="index">
                <v-text-field
                  v-model="item.name"
                  :rules="rules.name"
                  color="purple name-2"
                  label="Department Name"
                  required
                ></v-text-field>
                &nbsp;
                <v-autocomplete
                  v-model="item.discipline"
                  :rules="rules.discipline"
                  :items="disciplines"
                  label="Department Discipline"
                  class="ml-2"
                ></v-autocomplete>
                <v-autocomplete
                  v-model="item.campus"
                  :rules="rules.campus"
                  :items="campuses"
                  label="Department Campus"
                  class="ml-2"
                ></v-autocomplete>
                <!-- <template v-if="!editId">
                  <v-btn
                    class="custom-add-new-record-button"
                    type="button"
                    @click="addNewRow()"
                    >+</v-btn
                  >
                  <v-btn
                    v-if="index > 0"
                    class="custom-delete-new-record-button"
                    type="button"
                    @click="deleteRow(index)"
                    >-</v-btn
                  >
                </template> -->
              </v-row>
            </v-col>
          </v-container>
          <v-card-actions>
            <v-btn
              :disabled="$v.$invalid || submitting"
              class="custom-submit-button"
              type="submit"
              ><b-spinner small type="grow" v-if="submitting"></b-spinner>
              <span v-if="!editId">Submit</span>
              <span v-if="editId">Update</span>
            </v-btn>
            <v-btn text @click="cancelForm" class="custom-cancel-button">Cancel</v-btn>
          </v-card-actions>
        </v-form>
      </div>
    </v-card>
    <!-- form end -->

    <!-- table start -->
    <v-card>
      <v-card-title>
        Departments
        <div class="my-2 ml-2">
          <v-btn
            class="btn btn-sm btn-success font-weight-bold py-2 px-3 px-xxl-5 my-1"
            fab
            small
            @click="showForm"
          >
            <v-icon dark>mdi-plus</v-icon>
          </v-btn>
        </div>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search Departments"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers" :items="tableData" :search="search" loading>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            class="btn btn-sm btn-success font-weight-bold py-2 px-3 px-xxl-5 my-1"
            fab
            small
            @click="showEditForm(item)"
          >
            <v-icon dark>mdi-pencil</v-icon>
          </v-btn>
          &nbsp;
          <v-btn
            class="btn btn-sm btn-danger font-weight-bold py-2 px-3 px-xxl-5 my-1"
            fab
            small
            @click="askUserAboutDelete(item)"
          >
            <v-icon dark>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <!-- table end -->
  </div>
</template>

<script>
import DepartmentService from "@/MainServices/DepartmentService.js";
import DisciplineService from "@/MainServices/DisciplineService.js";
import CampusService from "@/MainServices/CampusService.js";
import { required, minLength } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      form: [{ name: "", discipline: null, campus: null }],
      disciplines: [],
      campuses: [],
      editId: null,
      deleteId: null,
      dialog: false,
      rules: {
        campus: [(val) => !!val || "Campuses is required"],
        name: [(val) => (val || "").length > 0 || "Name is required"],
        discipline: [(val) => !!val || "Discipline is required"],
      },
      snackbar: false,
      snackBarText: null,
      submitting: false,
      deleting: false,
      errorMessage: false,
      search: "",
      showFormData: false,
      headers: [
        { text: "Campus", align: "left", sortable: false, value: "campus", width: "20%" },
        {
          text: "Discipline",
          align: "left",
          sortable: false,
          value: "discipline",
          width: "30%",
        },
        {
          text: "Department",
          align: "left",
          sortable: false,
          value: "department",
          width: "30%",
        },

        { text: "Actions", value: "actions", sortable: false },
      ],
      tableData: [],
    };
  },
  validations: {
    form: {
      $each: {
        campus: { required },
        name: { minLength: minLength(3), required },
        discipline: { required },
      },
    },
  },
  components: {},

  computed: {},

  methods: {
    getInititalData() {
      DepartmentService.getInstituteDepartments()
        .then((res) => {
          let data = [];
          res.data.map((el) => {
            el.departments.map((el2) => {
              console.log(el2);
              data.push({
                campus: el.name,
                campus_id: el.id,
                discipline: el2.discipline.discipline,
                discipline_id: el2.discipline_id,
                department: el2.department,
                id: el2.id,
              });
            });
          });
          this.tableData = data;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // main methods
    showForm() {
      this.editId = null;
      this.showFormData = true;
      this.resetForm();
    },
    showEditForm(item) {
      this.form = [
        { name: item.department, discipline: item.discipline_id, campus: item.campus_id },
      ];
      this.editId = item.id;
      this.showFormData = true;
    },
    cancelForm() {
      this.resetForm();

      this.showFormData = false;
    },
    resetForm() {
      this.form = [{ name: "", discipline: null, campus: null }];
      this.editId = null;
      setTimeout(() => {
        this.$refs.form.reset();
      }, 100);
    },
    addNewRow() {
      this.form.push({ city: "", country_id: null });
    },
    deleteRow(index) {
      this.form.splice(index, 1);
    },
    submit() {
      this.submitting = true;
      if (this.editId) {
        LocationService.editCity(this.form, this.editId)
          .then((res) => {
            this.resetForm();
            this.getInititalData();
            this.submitting = false;
            this.snackBarText = "Department Updated successful!";
            this.snackbar = true;
          })
          .catch((error) => {
            this.errorMessage = true;
          });
      } else {
        DepartmentService.addNewDepartment(this.form)
          .then((res) => {
            this.resetForm();
            this.getInititalData();
            this.submitting = false;
            this.snackBarText = "Department Added successful!";
            this.snackbar = true;
          })
          .catch((error) => {
            this.errorMessage = true;
          });
      }
    },
    askUserAboutDelete(item) {
      this.deleteId = item.id;
      this.dialog = true;
    },
    deleteItem() {
      this.deleting = true;
      DepartmentService.deleteDepartment(this.deleteId)
        .then((res) => {
          this.editId = null;
          this.deleting = false;
          this.dialog = false;
          this.resetForm();
          this.getInititalData();
          this.snackBarText = "Department Deleted successful!";
          this.snackbar = true;
        })
        .catch((error) => {
          this.errorMessage = true;
          this.dialog = false;
        });
    },
  },
  mounted() {
    this.getInititalData();
    DisciplineService.getDisciplines()
      .then((res) => {
        res.data.map((el) => {
          this.disciplines.push({ value: el.id, text: el.discipline });
        });
      })
      .catch(() => {
        this.snackBarText = "something went wrong";
      });

    CampusService.getInstituteCampuses()
      .then((res) => {
        res.data.map((el) => {
          this.campuses.push({ value: el.id, text: el.name });
        });
      })
      .catch(() => {
        this.loading = false;
      });
  },
};
</script>
